import {
    arrayFrom, fetchFunc, isContainsClass, findElems,
    newFormData, toggleActive, findElem
}                                                     from "./helpers.ts";
import { index_php_key, product_id_key, success_key } from "./vars.js";
import { handleCommonModal }                          from "./helpers/modal";

function removeProductsFromWishlist() {
    const removeBtn = findElem('.create-order__del-btn'),
        form = newFormData();

    if (!removeBtn || isContainsClass('cart', removeBtn)) {
        return;
    }

    removeBtn.addEventListener('click', () => {
        const productIdsArr = arrayFrom(findElems('.wishlist__products-list [data-wishlist-prod-id]:checked'));

        productIdsArr.forEach(id => form.append('product_id[]', id.dataset.wishlistProdId))

        fetchFunc(index_php_key + 'account/wishlist.remove', form)
            .then(json => {
                if (json[success_key]) {
                    location.reload();
                }
            });
    });
}

function wishlist(selector) {
    const prodItemLinks = findElems(selector),
        wishlistTotalShows = findElems('.header__wishlist-content > span'),
        partUrl = index_php_key + 'account/wishlist',
        form = newFormData(),
        setTotalShow = total_count => arrayFrom(wishlistTotalShows).forEach(elem => elem.textContent = total_count);

    arrayFrom(prodItemLinks).forEach(link => {
        const addToWishlistBtn = findElem('.heart-wishlist-btn', link),
            removeFromWishlistBtn = findElem('.heart-wishlist-btn-clicked', link);

        link.onclick = e => {
            e.preventDefault();

            let productId,
                wishlistMethod = '.add';

            if (e.target.parentElement === addToWishlistBtn) {
                productId = addToWishlistBtn.dataset.wishlist;
            } else if (e.target.parentElement === removeFromWishlistBtn) {
                productId = removeFromWishlistBtn.dataset.wishlist;
                wishlistMethod = '.remove';
            } else {
                return;
            }

            form.set(product_id_key, productId);

            fetchFunc(partUrl + wishlistMethod, form)
                .then(json => {
                    if (json['total_count']) {
                        setTotalShow(json['total_count']);
                    }

                    if (json[success_key]) {
                        handleCommonModal({
                            content: json[success_key],
                            header: (json['total'] ?? ''),
                            isFullScreen: false,
                        });

                        toggleActive([addToWishlistBtn, removeFromWishlistBtn]);
                    }
                });
        };
    });
}

export { removeProductsFromWishlist, wishlist };