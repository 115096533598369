import {
    addClass, arrayFrom, isContainsClass,
    findElem, findElems, removeClass,
    debounce
} from "./helpers.ts";

function validatePasswordConfirmation(passwordField, passwordConfirmationField, invalidMessage) {
    if (passwordField.value !== passwordConfirmationField.value) {
        [passwordField, passwordConfirmationField, invalidMessage].forEach(field => addClass(field, ['d-block', 'invalid-conf']));
    } else {
        [passwordField, passwordConfirmationField, invalidMessage].forEach(field => removeClass(field, ['d-block', 'invalid-conf']));
    }
}

function validateForm() {
    arrayFrom(findElems('.needs-validation')).forEach(form => {
        form.addEventListener('submit', e => {
            const passwordField = findElem('[name=password]'),
                passwordConfirmationField = findElem('[name=confirm_password]'),
                invalidMessage = findElem('#invalid-password-conf');

            if (passwordField && passwordConfirmationField && invalidMessage) {
                validatePasswordConfirmation(passwordField, passwordConfirmationField, invalidMessage);

                [passwordField, passwordConfirmationField].forEach(field => field.oninput = () => {
                    const validConfirmPassDebounce = debounce(validatePasswordConfirmation, 300);
                    validConfirmPassDebounce(passwordField, passwordConfirmationField, invalidMessage)
                });
            }

            if (!form.checkValidity() || (passwordConfirmationField && isContainsClass('invalid-conf', passwordConfirmationField))) {
                e.preventDefault();
                e.stopPropagation();
            }

            addClass(form, 'was-validated');
        }, false);
    });
}

export { validateForm };