import { arrayFrom, findElems } from "./helpers.ts";

function parsePhone(e, phone) {
    /*if (('type' in e) && e.type === 'click') {
        return;
    }*/

    let tempValue = phone.value.replace(/^\+(38)/g, '').replace(/\D+/g, ''),
        pattern = '+38 (___) ___-__-__';

    // tempValue = tempValue.replace(/\D+/g, '');

    const oldSelectionStart = phone.selectionStart,
        isInvalidSelectionStart = oldSelectionStart < 5,
        setSelection = position => {
            phone.selectionStart = position;
            phone.selectionEnd = position;
        },
        findPositionSelection = string => {
            let indexLetter = 0;

            if (typeof string === 'undefined') {
                string = pattern;
            }

            while (typeof string[indexLetter] !== 'undefined' && string[indexLetter] !== '_' && string.length > indexLetter) {
                indexLetter++;
            }

            return indexLetter;
        };

    for (let numberIndex = 0; numberIndex < tempValue.length; numberIndex++) {
        if (typeof tempValue[numberIndex] !== 'undefined') {
            pattern = pattern.replace(/_/, tempValue[numberIndex]);
        }
    }

    phone.value = pattern;

    if ('inputType' in e) {
        if (isInvalidSelectionStart) {
            setSelection(findPositionSelection(e.value));
        }

        if (e.inputType === 'deleteContentBackward') {
            setSelection((isInvalidSelectionStart ? 5 : oldSelectionStart));
        } else if (e.inputType === 'insertText') {
            if (/\s/.test(pattern[oldSelectionStart] + pattern[oldSelectionStart + 1])) {
                setSelection(oldSelectionStart + 2);
            } else if (/[^_0-9]/.test(pattern[oldSelectionStart])) {
                setSelection(oldSelectionStart + 1);
            } else {
                setSelection(oldSelectionStart);
            }
        }
    } else if ('type' in e) {
        if (e.type === 'click' && isInvalidSelectionStart) {
            setSelection(5);
        } else if (e.type === 'focus' && isInvalidSelectionStart) {
            setSelection(findPositionSelection(pattern));
        }
    } else {
        setSelection(findPositionSelection(pattern));
    }
}

function parsePhoneNumber() {
    arrayFrom(findElems('input[type=tel]')).forEach(phone => {
        phone.addEventListener('input', e => parsePhone(e, phone));
        phone.addEventListener('focus', e => parsePhone(e, phone));
        phone.addEventListener('blur', e => parsePhone(e, phone));
        phone.addEventListener('click', e => parsePhone(e, phone));
    });
}

export { parsePhoneNumber };