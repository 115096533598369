import { fetchFunc, newFormData, findElem }           from "./helpers.ts";
import { showToast }                                  from "./toast.js";
import { checkout_confirm, error_key, index_php_key } from "./vars.js";

function processCTPOrder(form, oneClickForm) {
    const validateOrderLink = index_php_key + checkout_confirm + '.validateOrderInfo';

    fetchFunc(validateOrderLink, form)
        .then(json => {
            if (json['success']) {
                oneClickForm.submit();
            } else if (json['error']) {
                showToast('danger', json[error_key]);
            }
        });
}

function byeOneClickCTP() {
    const processOrderBtn = findElem('.pcart__to-cart'),
        oneClickForm = findElem('.choose_type_pay');

    if (!processOrderBtn || !oneClickForm) {
        return;
    }

    oneClickForm.addEventListener('submit', e => {
        if (!oneClickForm.checkValidity()) {
            return;
        }

        e.preventDefault();

        processCTPOrder(newFormData(oneClickForm), oneClickForm);
    });
}

export { byeOneClickCTP };