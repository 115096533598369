import { fetchFunc, findElem, showErrorInConsole } from "./helpers";
import { saveCookie }                              from "./cookie.js";
import { index_php_key }                           from "./vars.js";
import { type Offcanvas }                          from "bootstrap";

function showSomeContent() : void {
    // @ts-ignore
    const expires : number = +window.infoContentExpires;

    const checkClickLink = () : void => {
        const telegramLink = findElem('.subscribe-link.telegram');

        if (!telegramLink) {
            return;
        }

        telegramLink.addEventListener('click', () : void => {
            saveCookie('is_telegram_subscribed', 1, (expires * 365));

            fetchFunc(index_php_key + 'extension/info_content/module/info_content.saveClickToTelegram&ctj=1', {}).then();
        });
    };

    const processClose = () : void => {
        const closeBtn = findElem('.info-content-html [data-bs-dismiss="offcanvas"]');

        if (closeBtn) {
            closeBtn.addEventListener('click', () => saveCookie('is_show_info_content_today', 1, expires));
        }
    };

    try {
        // @ts-ignore
        let offcanvas : Offcanvas;
        const infoContentEl = <HTMLElement | null>findElem('.info-content-html');

        if (!infoContentEl) {
            return;
        }

        import("bootstrap/js/src/offcanvas.js")
            .then(module => offcanvas = module.default)
            .then(() => {
                // @ts-ignore
                const offcanvasInstance = new offcanvas(infoContentEl);

                offcanvasInstance.show();

                processClose();
                checkClickLink();
            })
            .catch(error => showErrorInConsole(error.message));

    } catch (e) {
    }
}

export { showSomeContent };