import { arrayFrom, findElems } from "./helpers.ts";
import { body_var }  from "./vars.js";

function toastHtml(selectorBg, content) {
    return [
        `<div class="toast align-items-center text-bg-${selectorBg} border-0 position-fixed" style="top: 10px; right: 5px; z-index: 2" role="alert" aria-live="assertive" aria-atomic="true">`,
        '<div class="d-flex gap-3">',
        `<div class="toast-body">${content}</div>`,
        '<button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>',
        '</div></div>',
    ].join('');
}

function showToast(selectorBg, contents) {
    let html = '';

    if (typeof contents === 'object') {
        for (const contentKey in contents) {
            html += toastHtml(selectorBg, contents[contentKey]);
        }
    } else {
        html = toastHtml(selectorBg, contents);
    }

    arrayFrom(findElems('.toast.hide')).forEach(toast => toast?.remove());

    body_var.insertAdjacentHTML('beforeend', html);

    const toastElList = findElems('.toast'),
        toastList = [...toastElList].map(toastEl => new bootstrap.Toast(toastEl, {autohide: false}));

    toastList.forEach(el => el.show());
}

export { showToast };