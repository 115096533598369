import {
    addClass, arrayFrom, removeElement,
    removeClass, takeSpinnerHtml, findElems
}                                                          from "./helpers.ts";
import { document_var, lazy_load_key, spinner_border_key } from "./vars.js";

function lazyLoadImgs(parentBlock = document_var) {
    const loadImage = img => {
        const parentBlock = img.parentElement;

        parentBlock.insertAdjacentHTML('afterbegin', takeSpinnerHtml('text-success'));
        addClass(parentBlock, lazy_load_key);

        img.srcset = img.dataset.srcset;

        img.addEventListener('load', () => {
            img.removeAttribute('data-srcset');

            removeClass(parentBlock, lazy_load_key);
            removeElement('.' + spinner_border_key, parentBlock);
        });
    };

    const observer = new IntersectionObserver((entries, obs) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                loadImage(entry.target);
                obs.unobserve(entry.target);
            }
        });
    });

    arrayFrom(findElems('[data-srcset]', parentBlock)).forEach(img => observer.observe(img));
}



export { lazyLoadImgs };