import { addClass, removeClass, throttle, findElem } from "./helpers.ts";

function toggleHeader() {
    const header = findElem('.header'),
        needToScroll = 3;

    let scrolled = 0,
        prevScrollPos = scrollY;

    function tryDetectDirection() {
        const currentScrollPos = scrollY,
            scrollDirection = currentScrollPos > prevScrollPos ? 'down' : 'up';

        scrolled++;

        if (scrollDirection === 'down' && scrolled >= needToScroll) {
            scrolled = 0;

            addClass(header, 'active-toggle');
        } else if (scrollDirection === 'up' || !currentScrollPos || currentScrollPos < 0) {
            scrolled = 0;

            removeClass(header, 'active-toggle');
        }

        prevScrollPos = currentScrollPos;
    }

    function stickyHeader() {
        tryDetectDirection();

        tryDetectDirection = throttle(tryDetectDirection, 100);

        window.addEventListener('scroll', tryDetectDirection);
    }

    stickyHeader();
}

export { toggleHeader };