import { type Modal }                                          from "bootstrap";
import { addClass, findElem, removeClass, showErrorInConsole } from "../helpers";

let modal : Modal | null;

type TypeHandleCommonModal = {
    content : string,
    header? : string,
    footer? : string,
    isFullScreen? : boolean
};

const handleCommonModal = async (parameters : TypeHandleCommonModal) : Promise<void> => {
    const {
        content, header = '', footer = '', isFullScreen = true
    } = parameters;

    const html = [
        '<div class="modal-header">',
        header,
        '<button class="btn-close" type="button" data-bs-dismiss="modal"></button>',
        '</div>',
        `<div class="modal-body">${content}</div>`,
        `<div class="modal-footer">${footer}</div>`,
    ].join('');

    // we need deleting backdrop because if the customer change products in modal cart (for example)
    // so the Modal of bootstrap twice insert in body the backdrop and there are backdrops will create error
    // for Modal of bootstrap and the page will be locked
    findElem('.modal-backdrop')?.remove();

    const modalEl = <HTMLElement | null>findElem('#common-modal'),
        modalContentEl = <HTMLElement | null>findElem('.modal-content', modalEl),
        modalDialogEl = <HTMLElement | null>findElem('.modal-dialog', modalEl);

    modalContentEl.innerHTML = html;

    if (isFullScreen) {
        addClass(modalDialogEl, 'modal-fullscreen-md-down');
    } else {
        removeClass(modalDialogEl, 'modal-fullscreen-md-down');
    }

    if (!modal) {
        modal = await import('bootstrap/js/src/modal.js')
            .then(module => modal = module.default)
            .catch(error => showErrorInConsole(error.message));
    }

    try {
        // @ts-ignore
        const modalInstance : Modal = new modal(modalEl);

        modalInstance.show();

        modalEl?.addEventListener('hidden.bs.modal', () : void => {
            // if a customer change something in modal window like modal cart
            // so in document.body inserting two codes for block page body
            // and after closing the modal window the page body still blocked
            document.body.style.overflow = '';
            document.body.style.paddingRight = '';
        });
    } catch (e) {
    }
}

export { handleCommonModal };