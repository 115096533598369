import { arrayFrom, findElems } from "./helpers.ts";

function checkedAllInputs(selectorParentBlock, selectorBlockForMainBtns, selectorBlockForOptionChecks) {
    const mainBlocks = findElems(selectorParentBlock);

    arrayFrom(mainBlocks).forEach(mainBlock => {
        const mainBtns = findElems(selectorBlockForMainBtns, mainBlock),
            optionCheckboxes = findElems(selectorBlockForOptionChecks + ' .option-check-input', mainBlock);

        arrayFrom(mainBtns).forEach(mainBtn => {
            mainBtn.addEventListener('click', () => {
                arrayFrom(optionCheckboxes).forEach(optionCheckbox => optionCheckbox.checked = !!mainBtn.checked);
            });
        });
    });
}

export { checkedAllInputs };