import {
    active_key, d_none_key, document_var, error_key,
    html_key, index_php_key, screenWidth, spinner_border_key
}                    from "./vars.js";
import {
    addClass, fetchFunc, redirect, removeElement,
    removeClass, takeSpinnerHtml, findElem,
    debounce
}                    from "./helpers";
import { showToast } from "./toast.js";

function search() {
    const form = findElem('.header__search-form');

    if (!form) {
        return;
    }

    const searchImg = findElem('.header__search-img'),
        searchField = <HTMLInputElement | null>findElem('.header__search-field'),
        searchSubmitBtn = <HTMLButtonElement | null>findElem('.header__search-btn'),
        searchBlock = findElem('.header__search-input-block'),
        errorBlock = findElem('.header__search-alert-error');

    const tryFindProducts = async (parentBlock : HTMLElement, keywords : string) : Promise<void> => {
        removeElement('.header__search-alert-result');
        removeElement('.header__search-alert-result-empty');

        parentBlock.insertAdjacentHTML('afterbegin', takeSpinnerHtml('text-success'));
        searchField.readOnly = true;
        searchSubmitBtn.disabled = true;

        await fetchFunc(index_php_key + 'common/search.liveSearch&search=' + encodeURIComponent(keywords), {})
            .then(json => {
                if (json[html_key]) {
                    form.closest('.header__search-inner').insertAdjacentHTML('beforeend', json[html_key]);

                    processResults(false);
                } else if (json[error_key]) {
                    showToast('danger', json[error_key]);
                }
            })
            .finally(() => {
                removeElement('.' + spinner_border_key, parentBlock);
                searchField.readOnly = false;
                searchSubmitBtn.disabled = false;
            });
    };

    const debounceTryFindProducts = debounce(tryFindProducts, 1000);

    const processResults = (isNeedRemoveSelector : boolean) : void => {
        const result = <HTMLElement | null>findElem('.header__search-alert-result'),
            emptyResult = <HTMLElement | null>findElem('.header__search-alert-result-empty');

        let selectors = [];

        screenWidth > 829 ? selectors.push(active_key, 'pc') : selectors.push(active_key);

        if (result) {
            if (isNeedRemoveSelector) {
                if (screenWidth <= 829) {
                    removeClass(result, selectors);
                }
            } else {
                addClass(result, selectors);
            }

            // isNeedRemoveSelector && screenWidth <= 829 ? removeSelector(result, selectors) : addSelector(result, selectors);
        }

        if (emptyResult) {
            if (isNeedRemoveSelector) {
                if (screenWidth <= 829) {
                    removeClass(emptyResult, selectors);
                }
            } else {
                addClass(emptyResult, selectors);
            }

            // isNeedRemoveSelector && screenWidth <= 829 ? removeSelector(emptyResult, selectors) : addSelector(emptyResult, selectors);
        }
    };

    const validateSearchValue = (searchVal : string) : void => {
        const isSearchValValid = searchVal.length >= 3;

        removeClass(searchSubmitBtn, d_none_key);
        removeClass(searchBlock, error_key);
        removeClass(errorBlock, active_key);
        removeClass(searchImg, error_key);

        addClass(searchImg, isSearchValValid ? active_key : error_key);

        processResults(!isSearchValValid);

        if (isSearchValValid) {
            debounceTryFindProducts(form.closest('.header__search-input-block'), searchVal);
        } else {
            addClass(searchSubmitBtn, d_none_key);
            addClass(searchBlock, error_key);
            addClass(errorBlock, active_key);
        }

        if (!isSearchValValid) {
            addClass(searchSubmitBtn, d_none_key);
            addClass(searchBlock, error_key);
            addClass(errorBlock, active_key);
        }
    };

    function handleFormSubmit(e : SubmitEvent) : void {
        e.preventDefault();

        if (this.checkValidity()) {
            redirect(this.action + encodeURIComponent(searchField.value));
        }
    }

    const handleInput = () : void => {
        validateSearchValue(searchField.value.trim());
    };

    const toggleResults = (searchResult : HTMLElement | null, searchEmptyResult : HTMLElement | null, isShow : boolean) : void => {
        if (searchResult) {
            isShow ? addClass(searchResult, active_key) : removeClass(searchResult, active_key);
        }

        if (searchEmptyResult) {
            isShow ? addClass(searchEmptyResult, active_key) : removeClass(searchEmptyResult, active_key);
        }
    };

    const handleClick = (e : Event) : void => {
        const searchResult = findElem('.header__search-alert-result'),
            searchEmptyResult = findElem('.header__search-alert-result-empty'),
            carouselIndicatorsContEl = findElem('.carousel-indicators'),
            targetEl = e.target as HTMLElement;

        if (carouselIndicatorsContEl) {
            carouselIndicatorsContEl.style.zIndex = '0';
        }

        if (targetEl == searchField) {
            if (!searchField.readOnly) {
                addClass(searchBlock, active_key);

                toggleResults(searchResult, searchEmptyResult, true);
            }
        } else {
            removeClass(searchBlock, [active_key, error_key]);
            removeClass(errorBlock, active_key);
            removeClass(searchImg, [active_key, error_key]);

            if (!targetEl.closest('.header__search-inner')) {
                toggleResults(searchResult, searchEmptyResult, false);
            }
        }
    };

    document_var.addEventListener('click', handleClick);
    searchField.addEventListener('input', handleInput);
    form.addEventListener('submit', handleFormSubmit);
}

export { search };